import React, { useEffect, useRef } from 'react';

const InactivityTimer = ({

  timeoutDuration = 5 * 60 * 1000, // Default to 5 minutes

  eventsToListen = [ 'mousemove', 'click', 'keydown', 'touchstart' ], // Default events

  onTimeout = () => window.location.reload(), // Default action (reload)

}) => {
  
  const timerRef = useRef(null);

  const resetTimer = () => {

    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(onTimeout, timeoutDuration);
  };

  useEffect(() => {

    eventsToListen.forEach((event) => {

      window.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {

      eventsToListen.forEach((event) => {

        window.removeEventListener(event, resetTimer);
      });

      clearTimeout(timerRef.current);
    };

  }, [timeoutDuration, eventsToListen, onTimeout]);

  return null;
};

export default InactivityTimer;
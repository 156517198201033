import React, { useState, useEffect, useRef } from 'react';

import ModalMessage from './ModalMessage';
import Loading      from './Loading';

import * as utils from './Utils';

import './App.css';

function App() {

  const [loading, setLoading] = useState(false);

  const [modalMessage,   setModalMessage  ] = useState(null);
  const [modalNextFocus, setModalNextFocus] = useState(null);
  const [modalCallback,  setModalCalback  ] = useState(null);

  const adminLogged = (window.location.hash.substring(1) && window.location.hash.substring(1) == "logged" ? true : false);

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  useEffect(() => {

    if (!adminLogged || localStorage.getItem('mdlAdmin') != 1) {

      localStorage.setItem('loginRedirect', '/admin#logged');
      window.location.href = '/login#admin';
      return;
    }

  }, []);

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }

    if (modalNextFocus) {

      modalNextFocus.focus();
      setModalNextFocus(null);
    }
  }; 

  const Logo = () => {

    return (
      <div className="flex justify-center items-center mb-2">
        <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
      </div>
    );
  };

  const handleEventsButtonClick = async () => {

    window.location.href = '/admin-events';
  }

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />

            <div>
              <h1 className="text-center text-1xl mb-4 font-bold">Painel de administrador:</h1>
            </div>

            <div className="flex justify-center items-center m-2">
              <button type="submit" className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                onClick={handleEventsButtonClick} style={{ minWidth: '300px' }}>
                Acessar eventos
              </button>
            </div>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       
      </div>
    </div>
  );
}

export default App;
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import ModalMessage    from './ModalMessage';
import Loading         from './Loading';
import InactivityTimer from './components/InactivityTimer';
import RankingsHeader  from './RankingsHeader'

import * as utils from './Utils';

import './App.css';

const fetchCategory = async (catUUID) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'rankings/category/'+ catUUID, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching category details: ', error);
    throw error;
  }
};

function App() {

  const [loading,  setLoading ] = useState(true);
  const [category, setCategory] = useState([]);

  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const { catUUID } = useParams();

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  useEffect(() => {

    console.log("catUUID: " + catUUID);

    const loadCategory = async () => {

      try {

        const category = await fetchCategory(catUUID);

        if (category.status == 'OK') {

          if (category != null && category.rankings != null) {

            setCategory(category);
            setLoading(false);
          }
          else {
  
            throw new Error('Could not retrieve category information');
          }
        }
        else {

          setModalMessage(category.msg);
          setModalCalback(() => {
            return () => {
              window.location.reload();
            };
          });
        }
      }
      catch(error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.reload();
          };
        });
      }
    };

    loadCategory();

  }, []);

  const handlePageTimeout = async () => {

    const category = await fetchCategory(catUUID);
    if (category.status == 'OK' && category != null && category.rankings != null)
      setCategory(category);
    else
      window.location.reload();
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null); // Clear the modal message
  
    if (modalCallback) {
  
      modalCallback();
      setModalCalback(null);
    }
  };

  let place     = 0;
  let lastScore = null;

  const [selectedGroup, setSelectedGroup] = useState(-1);
  
  const handleGroupChange = (event) => {

    setSelectedGroup(event.target.value);
    //console.log("Filter set: " + event.target.value)
  };
  
  const handleBackButton = () => {

    window.history.back();
  }

  const formatDate = (dateString) => {

    if (dateString && dateString.length >= 19) {

      const date    = new Date(dateString);
      const day     = String(date.getDate()).padStart(2, '0');
      const month   = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year    = date.getFullYear();
      const hours   = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${day}/${month}/${year}\nàs ${hours}:${minutes}:${seconds}`;
    }
    else
      return dateString;
  };  

  const LiveIcon = ({ msg }) => {
    return (
      <div className="relative inline-block">
        {(msg > 0 || msg.length > 0) > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold text-white bg-red-500 rounded-full transform translate-x-0/4 -translate-y-2">
            {msg}
          </span>
        )}
      </div>
    );
  };  

  const filteredItems =
    category.rankings != null ? category.rankings.filter(item => (selectedGroup < 0 || item.strength == selectedGroup)) : null;

  return (
    <div style={bodyStyle}>
        <InactivityTimer 
          timeoutDuration={ 1 * 60 * 1000 /* 1 min */ }
          onTimeout={handlePageTimeout}
        />
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <RankingsHeader label={category.catName.toUpperCase()} onClick={handleBackButton} />
            
            {category.rankings.length==0&&(
              <div className="mt-20">
                <h1 className="text-center text-1xl mb-6">Nenhuma pontuação encontrada.</h1>
              </div>
            )}

            {category.rankings.length>=0&&(
              <div className="mt-20">
                
                <div>
                  <div className={"flex flex-col justify-between items-left mb-2 p-2 rounded-lg"}>
                    {category.rankingsLiveNow>0&&<LiveIcon msg={"Ao vivo"} />}
                    <p className="text-center text-s ">Última atualização:</p>
                    <p className="text-center text-s font-semibold">{formatDate(category.rankingsUpdatedOn)}</p>
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <div>
                  </div>
                </div>

                <label className="bg-orange-400 flex justify-center mb-6 font-bold rounded">
                  <select style={{ minWidth: '300px', minHeight: '40px' }} className="focus:outline-none bg-orange-400 text-white text-center justify-center my-1 font-bold"
                    value={selectedGroup}
                    onChange={handleGroupChange}
                  >
                    {category.strengths.map((option) => (
                      <option key={option.value} value={option.value}> {option.text}</option>
                    ))}
                  </select>
                </label>
              </div>
            )}

            <ul className="grid grid-cols-1 -mx-2 items-end justify-end">
              {filteredItems.map((item) => {

                const currScore = item.qualifyHits + 1000 * item.finalHits;
                if (currScore != lastScore) {

                  lastScore = currScore;
                  place++;
                }

                const strength = (item.strength > 0 ? " - Força " + String.fromCharCode(64 + item.strength) : '');

                return(
                  <li 
                    className={"flex flex-col justify-between items-center mb-6 bg-white p-4 rounded-lg shadow-md border-gray-300 border-2"}
                    style={{ userSelect: 'none' }}
                  >

                    <div className="rounded grid grid-cols-4 gap-2 items-center text-center bg-orange-100">

                      <div style={{ minWidth: '75px' }}>
                        <p>Lugar</p>
                      </div>

                      <div style={{ minWidth: '75px' }}>
                        <p>Classif.</p>
                      </div>

                      <div style={{ minWidth: '75px' }}>
                        <p>Final</p>
                      </div>

                      <div style={{ minWidth: '75px' }}>
                        <p>Total</p>
                      </div>

                    </div>

                    <div className="font-semibold mb-2 rounded grid grid-cols-4 gap-2 items-center text-center bg-orange-100">

                      <div style={{ minWidth: '75px' }}>
                        <p>{"#" + place}</p>
                      </div>

                      <div style={{ minWidth: '75px' }}>
                        <p>{item.qualifyHits}/{item.qualifyTotal}</p>
                      </div>

                      <div style={{ minWidth: '75px' }}>
                        <p>{item.finalHits}/{item.finalTotal}</p>
                      </div>

                      <div style={{ minWidth: '75px' }}>
                        <p>{item.qualifyHits + item.finalHits}/{item.qualifyTotal + item.finalTotal}</p>
                      </div>

                    </div>

                    <div>
                      <p className="text-lg font-semibold mb-2">NÚMERO {item.badge}{strength}</p>
                    </div>

                    <ul className="grid grid-cols-1 text-center">
                      { item.participants.map((participant, index2) => {

                        return (
                        <li key={index2} className="mb-0 p-0 rounded">
                          <div>
                            <p className="mb-0">{participant.toUpperCase()}</p>
                          </div>
                        </li>
                      )})}
                    </ul>

                  </li>
                )}
              )}
            </ul>

            <div className="mb-16">
            </div>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}
      </div>
    </div>
  );
}

export default App;
import React, { useState, useEffect, useRef } from 'react';

import ModalMessage    from './ModalMessage';
import Loading         from './Loading';
import InactivityTimer from './components/InactivityTimer';

import * as utils from './Utils';

import './App.css';

const fetchBadges = async (cpf) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/users/badges/' + cpf, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();

    return data;
  }
  catch (error) {

    console.error('Error fetching badges: ', error);
    throw error;
  }
};

function App() {

  const [loading,    setLoading   ] = useState(false);
  const [userBadges, setUserBadges] = useState([]);

  const [thisCPF,    setThisCPF   ] = useState('');
  const [lastCPF,    setLastCPF   ] = useState('');
  const [isCPFValid, setIsCPFValid] = useState(false);

  const [modalMessage,   setModalMessage  ] = useState(null);
  const [modalNextFocus, setModalNextFocus] = useState(null);
  const [modalCallback,  setModalCalback  ] = useState(null);

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  useEffect(() => {

  }, []);

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }

    if (modalNextFocus) {

      modalNextFocus.focus();
      setModalNextFocus(null);
    }
  }; 

  const handleChangeCPF = async (value) => {

    value = value.trim().replace(/\D/g, '');

    setIsCPFValid( utils.validateCPF(value) );

    setThisCPF(value);
  };

  const Logo = () => {

    return (
      <div className="flex justify-center items-center mb-2">
        <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
      </div>
    );
  };

  const handleSearchButtonClick = async () => {

    if (!isCPFValid) {

      setModalMessage("O CPF digitado não é válido.");
      setModalNextFocus(document.getElementById('cpfField'));
      return;
    }

    if (lastCPF == thisCPF) {

      //setModalMessage("Mesmo CPF digitado.");
      //setModalNextFocus(document.getElementById('cpfField'));
      return;      
    }

    //console.log("thisCPF=" + thisCPF);
    //console.log("lastCPF=" + lastCPF);

    setLastCPF(thisCPF);

    setLoading(true);

    const loadBadges = async (cpf) => {

      try {

        const data = await fetchBadges(cpf);

        if (data != null && data.items != null) {

          if (data.items.length == 0) {

            setModalMessage("Nenhum número encontrado com este CPF.");
            setModalNextFocus(document.getElementById('cpfField'));
          }

          setUserBadges(data.items);

          setLoading(false);
        }
        else {

          throw new Error('Could not retrieve badges information');
        }
      }
      catch (error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.reload();
          };        
        });
      }
    };

    loadBadges(thisCPF);
  }

  const handlePageTimeout = () => {

    setLastCPF(''); //allow searching for the same CPF
  };  

  return (
    <div style={bodyStyle}>
        <InactivityTimer 
          timeoutDuration={ 1 * 60 * 1000 /* 1 min */ }
          onTimeout={handlePageTimeout}
        />
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />
            
            <div className="p-2">
              <label className="block">Digite o CPF do participante:</label>
              <input 
                id="cpfField"
                autocomplete="cpf"
                type="text"
                value={thisCPF}
                name="cpf"
                maxLength="11"
                placeholder="Digite o CPF"
                className="border-2 rounded w-full p-1"
                onChange={(e) => handleChangeCPF(e.target.value)}
                inputMode="numeric"
                pattern="[0-9]*"
                required
              />
            </div>

            <div className="flex justify-center items-center m-2">
              <button type="submit" className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ${isCPFValid ? '' : 'opacity-50'}`}
                onClick={handleSearchButtonClick} style={{ minWidth: '150px' }}>
                Buscar Números
              </button>
            </div>

            {userBadges.length==0&&(<div>
              <h1 className="text-center text-1xl mb-6">Consulta apenas para compras on-line.<br/>Alterações feitas manualmente pela secretaria não serão apresentadas.</h1>
            </div>
            )}

            {userBadges.length>0&&(
            <div>
              <div className="mt-8">
                <h1 className="text-center text-1xl font-bold mb-6">Números encontrados:</h1>
              </div>
            </div>
            )}

            <ul className="grid grid-cols-1 -mx-2">
              {userBadges.map((item, index1) => (
                <li 
                  className={"flex flex-col justify-between items-left mb-6 bg-white p-4 rounded-lg shadow-md border-gray-300 border-2"}
                  style={{ userSelect: 'none' }}
                >
                  <div>
                    <p className="text-lg font-semibold mb-0">{item.eventName.toUpperCase()}</p>
                  </div>

                  <ul className="mt-4">
                    <li className="border mb-2 p-4 rounded bg-orange-50">
                      <div>
                        <p className="text-lg mb-2 font-bold">{item.categoryName.toUpperCase()}</p>
                        <div className="mb-2">
                          <p className="text-l font-semibold mb-0">{"Número: " + (item.userBadge?item.userBadge:'N/A')}</p>
                          {item.userStrength? <p className="text-l font-semibold mb-0">{"Força: " + item.userStrength}</p> : ''}
                        </div>
                      </div>
                    </li>
                  </ul>


                </li>
              ))}
            </ul>

            <div className="mb-16">
            </div>

            {modalMessage && (
              <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
            )}                       

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       
      </div>
    </div>
  );
}

export default App;
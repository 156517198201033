// ConfirmModal.js
import React, { useState, useEffect, useRef } from 'react';

import * as utils from './Utils';

const ConfirmModal = ({ user, isOpen, onClose, onConfirm }) => {

  const [username,  setUsername ] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const [isPassword1Valid, setIsPassword1Valid] = useState(false);
  const [isPassword2Valid, setIsPassword2Valid] = useState(false);

  const password1InputRef = useRef(null);
  const password2InputRef = useRef(null);  

  useEffect(() => {
    setUsername(user);
    setPassword1('');
    setPassword2('');
    setIsPassword1Valid(false);
    setIsPassword2Valid(false);
    if (isOpen)
      password1InputRef.current.focus();
  }, [isOpen]);  

  const handlePassword1Change = (e) => {
    const enteredPW = e.target.value;
    setPassword1(enteredPW);
    setIsPassword1Valid( utils.validatePassword(enteredPW) );
    setIsPassword2Valid( enteredPW === password2 );
    //console.debug('password1='+enteredPW);
  };  

  const handlePassword2Change = (e) => {
    const enteredPW = e.target.value;
    setPassword2(enteredPW);
    setIsPassword1Valid( utils.validatePassword(enteredPW) );
    setIsPassword2Valid( password1 === enteredPW );
    //console.debug('password2='+enteredPW);
  };  

  const handleConfirm = () => {
    if (isPassword1Valid && isPassword2Valid) {
      onConfirm(password1);
      if (onClose)
        onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleConfirm();
    } else if (e.key === 'Escape') {
      if (onClose)
        onClose();
    }
  };  

  return (
    <div
      className={`fixed inset-0 ${isOpen ? '' : 'hidden'} flex items-center justify-center m-2`}
      onKeyDown={handleKeyDown}
      tabIndex="0"
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 rounded-lg z-10">

        <h2 className="text-lg font-bold mb-2">Defina uma senha:</h2>
        <p className="mb-4">(use pelo menos 8 caracteres)</p>

        <input
          id="username"
          type="text"
          value={username}
          className={"border rounded p-2 mb-4 w-full"}
          required
          disabled="true"
          style={{ display: 'none'}}
        />

        <input
          id="password"
          type="password"
          placeholder="Digite uma senha"
          value={password1}
          onChange={handlePassword1Change}
          className={`border rounded p-2 mb-4 w-full ${isPassword1Valid ? 'border-green-500' : 'border-red-500'}`}
          ref={password1InputRef} 
          onKeyDown={handleKeyDown}
        />

        <input
          type="password"
          placeholder="Repita a mesma senha"
          value={password2}
          onChange={handlePassword2Change}
          className={`border rounded p-2 mb-4 w-full ${isPassword2Valid ? 'border-green-500' : 'border-red-500'}`}
          ref={password2InputRef} 
          onKeyDown={handleKeyDown}
        />

        <div className="flex justify-end">
          {onClose&&(<button className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2" onClick={onClose}>
            Cancelar
          </button>)}
          <button 
            className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ${isPassword1Valid && isPassword2Valid ? '' : 'opacity-50 cursor-not-allowed'}`}
            onClick={handleConfirm} disabled={!isPassword1Valid && !isPassword2Valid} style={{ minWidth: '150px' }}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;

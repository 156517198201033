// ConfirmModal.js
import React, { useState, useEffect, useRef } from 'react';

const ConfirmModal = ({ message, okLabel, cancelLabel, onOk, onCancel }) => {

  return (
    <div className="fixed inset-0 flex items-center justify-center m-2">
      <div className="fixed inset-0 bg-black opacity-90"></div>
      <div className="bg-white p-6 rounded-lg z-10">
        <h2 className="text-lg font-bold mb-4 text-center" style={{ maxWidth: '450px', whiteSpace: 'pre-line' }}>{message}</h2>
        <div className="flex justify-end">
          <button className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2" onClick={onCancel} style={{ minWidth: '150px' }}>
            {cancelLabel}
          </button>
          <button className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold" onClick={onOk} style={{ minWidth: '150px' }}>
            {okLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;

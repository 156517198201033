import React, { useState, useEffect, useRef } from 'react';

import InputMask from 'react-input-mask';

import ModalMessage from './ModalMessage';
import './App.css';

import * as utils from './Utils';

class EventHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      eventInfo: []
    };
  }

  componentDidMount() {

    /*fetch(process.env.REACT_APP_API_ENDPOINT + '/events/' + this.props.uuid + '/banner', {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }}
      ).then(response => {

        if (!response.ok)
          throw new Error('Failed to fetch event banner');

        return response.blob();
      })
      .then(imageBlob => {

        const imageUrl = URL.createObjectURL(imageBlob);
        this.setState({ imageUrl });
      })
      .catch(error => {

        console.error('Error fetching event banner:', error);
      }
    );*/

    fetch(process.env.REACT_APP_API_ENDPOINT + '/events/' + this.props.uuid, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }}
      ).then(response => {

        if (!response.ok)
          throw new Error('Failed to fetch event info');

        return response.json();
      })
      .then(eventInfo => {

        //console.log('test: ', eventInfo);

        this.setState({ eventInfo });
      })
      .catch(error => {

        console.error('Error fetching event info:', error);
      }
    );
  }

  componentWillUnmount() {

    URL.revokeObjectURL(this.state.imageUrl);
  }  

  render() {
    //const { imageUrl } = this.state;
    const { eventInfo } = this.state;
    return (
      <div className="fixed text-center justify-center items-center top-0 left-0 right-0 bg-white border-2 border-gray-200">
        <div className="container text-center justify-center items-center bg-white p-4 mx-auto max-w-screen-md">
          <div className="flex flex-col text-center items-center min-h-[45px] justify-center bg-white max-h-12">
            {eventInfo && <span className="text-1xl font-bold text-black">{eventInfo.name}</span>}
            {(eventInfo && eventInfo.batch) && <span className="text-1xl text-black">{eventInfo.batch}</span>}
          </div>
        </div>
      </div>
    );
  }
}

export default EventHeader;
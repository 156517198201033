import React, { useState, useEffect, useRef } from 'react';

import InputMask from 'react-input-mask';

import ConfirmModal    from './ConfirmModal-registration-email';
import ModalMessage    from './ModalMessage';
import Loading         from './Loading';
import EventHeader     from './EventHeader'
import InactivityTimer from './components/InactivityTimer';

import './App.css';

import * as utils from './Utils';

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" &&  window.performance.navigation.type === 2 );
  if ( historyTraversal ) { window.location.reload(); } });

const fetchCart = async (uuid) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/cart/' + uuid, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching items: ', error);
    throw error;
  }
};

function validateName(name) {

  return name.length > 2;
}

function App() {

  const [loading,            setLoading         ] = useState(true);
  const [itemCategories,     setItemCategories  ] = useState([]);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalMessage,       setModalMessage    ] = useState(null);
  const [modalNextFocus,     setModalNextFocus  ] = useState(null);
  const [modalCallback,      setModalCalback    ] = useState(null);

  const inputRefFirstName = useRef(null);
  const inputRefFirstCPF  = useRef(null);

  const eventUUID = localStorage.getItem('eventUUID');
  const cartUUID  = localStorage.getItem('cartUUID' );
  const mdlToken  = localStorage.getItem('mdlToken' );

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  useEffect(() => {

    //console.log('Effect is running');

    const loadPurchasedItems = async () => {

      try {

        //console.log('cartUUID='+cartUUID);

        const event = await fetchCart(cartUUID);

        if (event != null && event.items != null && event.items.length > 0) {

          if (event.cartStatus == 'Registered') {

            setModalMessage("Esta inscrição já foi registrada.");
            setModalCalback(() => {
              return () => {
                window.location.href = '/success';
              };        
            });
          }
          else {

            setItemCategories(event.items);
            setLoading(false);
  
            if (inputRefFirstCPF.current)
              inputRefFirstCPF.current.focus();
          }
        }
        else {

          throw new Error('Could not retrieve items information');
        }
      }
      catch (error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.reload();
          };        
        });
      }
    };

    loadPurchasedItems();
  
  }, []);

  const handleCPFChange = async (itemUUID, index1, index2, target) => {

    const enteredCPF = target.value.trim().replace(/\D/g, '');

    //console.log("CPF index: " + index1 + ", " + index2 + ' / value:' + enteredCPF);

    const item = itemCategories[index1];
    if (item) {

      item.participants[index2].cpf = enteredCPF;
      //console.log(item.participants[index]);

      if (/*item.participants[index2].name.length === 0 &&*/ utils.validateCPF(enteredCPF)) {

        try {

          const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/lookup/' + enteredCPF, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
    
          if (!response.ok)
            throw new Error(response.message);
    
          const result = await response.json();
          if (!result)
            throw new Error("Unknown result");
          else if (result.status == "AlreadyExists" || result.status == "LookUp") {

            if (result.nickname.length > 0)
            item.participants[index2].name = result.nickname;
            else if (result.name.length > 0)
            item.participants[index2].name = result.name;

            item.participants[index2].userUUID = result.userUUID;
          }
        }
        catch (error) {
        }

        itemCategories.map(t => {

          t.participants.map(p => {

            if (p.cpf == enteredCPF) {

              if (validateName(p.name)) { 

                item.participants[index2].name = p.name;
                //console.log("found: " + p.name);

                const myName = document.getElementById('Name#'+index1+'#'+index2);
                if (myName) {

                  myName.value = p.name;

                  handleNameChange(itemUUID, index1, index2, myName);

                  //console.log("set: " + myName.id);
                }
              }
            }
          }

        )});
      }
    }

    target.value = enteredCPF;

    target.className = 'border-2 rounded p-1 ' + (utils.validateCPF(enteredCPF) ? 'border-green-500 bg-white' : 'border-red-500 bg-yellow-100');
    return true;
  };

  const handleNameChange = (itemUUID, index1, index2, target) => {

    const enteredName = target.value.toUpperCase();

    //console.log("Name index: " + index1 + ", " + index2);

    const item = itemCategories[index1];
    if (item) {

      //console.log(JSON.stringify(item));

      item.participants[index2].name = enteredName;

      if (item.participants[index2].cpf.length === 0 && validateName(enteredName)) {

        itemCategories.map(t => {

          t.participants.map(p => {

            if (p.name.trim().toUpperCase() == enteredName.trim().toUpperCase()) {

              if (utils.validateCPF(p.cpf)) { 

                item.participants[index2].cpf = p.cpf;
                //console.log("found: " + p.cpf);

                const myCPF = document.getElementById('CPF#'+index1+'#'+index2);
                if (myCPF) {

                  myCPF.value = p.cpf;

                  handleCPFChange(itemUUID, index1, index2, myCPF);

                  //console.log("set: " + myCPF.id);
                }
              }
            }
          }
        )});
      }

      //console.log(item.participants[index]);
    }

    target.value = enteredName;

    target.className = 'border-2 rounded p-1 ' + (validateName(enteredName) ? 'border-green-500 bg-white' : 'border-red-500 bg-yellow-100');
    return true;
  };

  const handleBadgeChange = async (itemUUID, index1, target) => {

    const enteredBadge = target.value.trim();

    const item = itemCategories[index1];
    if (item)
      item.badgeSel = enteredBadge;

    target.value = enteredBadge;

    return true;
  };

  const handleStrengthChange = async (itemUUID, index1, target) => {

    const enteredStrength = target.value.trim();

    const item = itemCategories[index1];
    if (item)
      item.strengthSel = enteredStrength;

    target.value = enteredStrength;

    return true;
  };

  const handleFinishButtonClick = async () => {

    { //find invalid CPFs or names:

      console.log('out: ' + JSON.stringify(itemCategories));

      var i1 = 0;
      for(const t1 of itemCategories) {

        if (t1.badgesAvail && t1.badgesAvail.length > 0 && t1.badgeSel.length == '') {

          setModalNextFocus(document.getElementById('Badge#'+i1));
          setModalMessage("Selecione o número desejado.");
          return;
        }

        if (t1.badgesAvail && t1.badgesAvail.length > 0 && t1.badgeSel.length != '') {

          var i2 = 0;
          for(const t2 of itemCategories) {

            if (t1 != t2 && t2.badgesAvail && t2.badgesAvail.length > 0 && t1.itemUUID == t2.itemUUID && t1.badgeSel == t2.badgeSel) {

              setModalNextFocus(document.getElementById('Badge#'+i2));
              setModalMessage("Selecione outro número.");
              return;
            }

            i2++;
          }
        }

        if (t1.strengthsAvail && t1.strengthsAvail.length > 0 && t1.strengthSel.length == '') {

          setModalNextFocus(document.getElementById('Strength#'+i1));
          setModalMessage("Selecione a força desejada.");
          return;
        }

        var i2 = 0;
        for(const p1 of t1.participants) {

          if (p1.cpf.length === 0) {

            setModalNextFocus(document.getElementById('CPF#'+i1+'#'+i2));
            setModalMessage("O CPF de cada participante deve ser preenchido.");
            return;
          }

          if (!utils.validateCPF(p1.cpf)) {

            setModalNextFocus(document.getElementById('CPF#'+i1+'#'+i2));
            setModalMessage("O CPF fornecido não é válido: " + p1.cpf);
            return;
          }

          if (!validateName(p1.name)) {

            setModalNextFocus(document.getElementById('Name#'+i1+'#'+i2));
            setModalMessage("O apelido ou nome de cada participante deve ser preenchido.");
            return;
          }

          {
            var j1 = 0;
            for(const t2 of itemCategories) {
      
              var j2 = 0;
              for(const p2 of t2.participants) {

                if ((i1 == i2) && (j1 == j2))
                  continue;
      
                if (p1.cpf === p2.cpf && p1.name !== p2.name) {
      
                  setModalNextFocus(document.getElementById('Name#'+j1+'#'+j2));
                  setModalMessage("O mesmo CPF aparece com nomes diferentes.");
                  return;
                }
      
                if (p1.name === p2.name && p1.cpf !== p2.cpf) {
      
                  setModalNextFocus(document.getElementById('CPF#'+j1+'#'+j2));
                  setModalMessage("O mesmo nome aparece com CPFs diferentes.");
                  return;
                }
      
                j2++;
              }
      
              j1++;
            }
          }

          i2++;
        }

        i1++;
      }
    }

    { //find duplicate CPFs or names on the same item:
      
      var i1 = 0;
      for(const t of itemCategories) {

        var i2 = 0;
        for(const p1 of t.participants) {

          var index3 = 0;
          for(const p2 of t.participants) {

            if (i2 != index3 && p1.cpf == p2.cpf) {

              setModalNextFocus(document.getElementById('CPF#'+i1+'#'+index3));
              setModalMessage("Há um CPF repetido na mesma inscrição: " + p2.cpf);
              return;
            }

            if (i2 != index3 && p1.name == p2.name) {

              setModalNextFocus(document.getElementById('Name#'+i1+'#'+index3));
              setModalMessage("Há um apelido ou nome repetido na mesma inscrição: " + p2.name);
              return;
            }

            index3++;
          }

          i2++;
        }

        i1++;
      }
    }

    setLoading(true);

    //console.log('out: ' + JSON.stringify(itemCategories));

    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'cart/' + cartUUID + '/participants', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: itemCategories,
        }),
      });

      if (!response.ok)
        throw new Error(response.message);

      const result = await response.json();
      if (!result || result.status != 'OK')
        throw new Error("Result: " + result.msg);

      localStorage.setItem('mdlCartPaid',       '');
      localStorage.setItem('mdlCartRegistered', cartUUID);

      //console.log("mdlCartPaid RESET");

      window.location.href = '/success';
    }
    catch (error) {

      console.error('Error updating participants: ', error);

      setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }
  };

  const handleConfirmModalClose = () => {
    
    setConfirmModalOpen(false);
  };

  const handleConfirmPurchase = (email) => {

    setModalMessage("PARABÉNS! Sua inscrição foi finalizada!");
    
    //console.log(`Registration confirmed with email: ${email}`);
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }

    if (modalNextFocus) {

      modalNextFocus.focus();
      setModalNextFocus(null);
    }
  }; 

  const handlePageTimeout = () => {

    window.location.reload();
  };     

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
      <InactivityTimer 
        timeoutDuration={ 60 * 60 * 1000 /* 60 min */ }
        onTimeout={handlePageTimeout}
      />
      {loading && (<Loading />)}
        {!loading && (
          <div>
            <div className="flex justify-center items-center mb-4">
              <EventHeader uuid={eventUUID} />
            </div>
            <div className="mt-20">
            <h1 className="text-center text-1xl font-bold mb-6">PREENCHA OS DADOS:</h1>
            </div>

            <ul className="grid grid-cols-1 -mx-2">
              {itemCategories.map((item, index1) => (
                <li 
                  key={item.itemUUID}
                  className={"flex flex-col justify-between items-left mb-6 bg-white p-4 rounded-lg shadow-md border-gray-300 border-2"}
                  style={{ userSelect: 'none' }}
                >
                  <div>
                    <p className="text-lg font-semibold mb-0">{(index1 + 1) + ') ' + item.name.toUpperCase()}</p>
                  </div>

                  {(item.desc && item.strengthsAvail && item.strengthsAvail.length > 1) && (
                    <div className="flex justify-between items-center mt-3 mb-3 shadow-md border border-grey-300 bg-white">
                      <label className="mx-3 my-1 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: item.desc }} />
                    </div>)
                  }

                  {item.badgesAvail && item.badgesAvail.length > 0 && (
                    <div className="p-1">
                      <label className="block font-bold">Número:</label>
                      <select
                        id={'Badge#'+index1}
                        className="form-select border-2 rounded w-full p-1"
                        name="badge"
                        defaultValue={item.badgeSel}
                        onChange={(e) => handleBadgeChange(item.itemUUID, index1, e.target)}
                        onBlur={(e) => handleBadgeChange(item.itemUUID, index1, e.target)}                        
                        required>
                        <option value="">Selecione</option>
                        {item.badgesAvail.map((ba) => (
                        <option key={ba} value={ba}>
                          {ba}
                        </option>
                        ))}
                      </select>
                    </div>              
                  )}

                  {item.strengthsAvail && item.strengthsAvail.length > 1 && (
                    <div className="p-1">
                      <label className="block font-bold">Força:</label>
                      <select
                        id={'Strength#'+index1}
                        className="form-select border-2 rounded w-full p-1"
                        name="strength"
                        defaultValue={item.strengthSel}
                        onChange={(e) => handleStrengthChange(item.itemUUID, index1, e.target)}
                        onBlur={(e) => handleStrengthChange(item.itemUUID, index1, e.target)}                        
                        required>
                        <option value="">Selecione</option>
                        {item.strengthsAvail.map((sa) => (
                        <option key={sa} value={sa}>
                          {sa}
                        </option>
                        ))}
                      </select>
                    </div>              
                  )}

                  {item.registrationInPerson == 1 && (
                    <div className="p-1 text-center">
                      <label className="block">A inscrição dos participantes dessa categoria será presencial no dia do evento.</label>
                    </div>              
                  )}

                  <ul className="mt-4">
                    { item.participants.map((participant, index2) => {

                      return (
                      <li key={index2} className="border mb-4 p-4 rounded bg-orange-50">
                        <div>
                          <p className="mb-2 font-bold">Participante {index2 + 1}:</p>
                          <div>
                            <div className="flex mb-2">
                              <label style={{ width: '150px' }}>CPF:</label>
                              <input
                                //InputMask
                                id={'CPF#'+index1+'#'+index2}
                                ref={index1 === 0 && index2 === 0 ? inputRefFirstCPF : null}
                                type="text"
                                maxLength="11"
                                placeholder="Digite o CPF"
                                className="border-2 rounded p-1"
                                //style={{ maxWidth: '150px' }}
                                //mask="999.999.999-99"
                                //maskChar="_"
                                defaultValue={participant.cpf}
                                onChange={(e) => handleCPFChange(item.itemUUID, index1, index2, e.target)}
                                onBlur={(e) => handleCPFChange(item.itemUUID, index1, index2, e.target)}
                              />
                            </div>
                            <div className="flex">
                              <label style={{ width: '150px' }}>Nome ou apelido:</label>
                              <input
                                id={'Name#'+index1+'#'+index2}
                                ref={index1 === 0 && index2 === 0 ? inputRefFirstName : null}
                                type="text"
                                maxLength={100}
                                placeholder="Digite nome ou apelido"
                                className={`border-2 rounded p-1`}
                                //style={{ maxWidth: '350px' }}
                                defaultValue={participant.name}
                                onChange={(e) => handleNameChange(item.itemUUID, index1, index2, e.target)}
                                onBlur={(e) => handleNameChange(item.itemUUID, index1, index2, e.target)}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    )})}
                  </ul>

                </li>
              ))}
            </ul>

            <div className="mb-16">
            </div>

            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300">
              <div className="container bg-white mx-auto p-4 max-w-screen-md">
                <div className="flex justify-end items-center">
                  <button className="bg-orange-400 text-white text-xl font-bold px-4 py-2 rounded"
                    onClick={handleFinishButtonClick} style={{ minWidth: '150px' }}>
                    Finalizar
                  </button>
                </div>
              </div>
            </div>

            <ConfirmModal
              isOpen={isConfirmModalOpen}
              onClose={handleConfirmModalClose}
              onConfirm={handleConfirmPurchase}
            /> 
            {modalMessage && (
              <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
            )}                       

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       
      </div>
    </div>
  );
}

export default App;
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const GoogleTagManager = () => {
  useEffect(() => {
    const gtmScript = document.createElement('noscript');
    gtmScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KN62J93L"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(gtmScript);
  }, []);

  return (
    <>
      <Helmet>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KN62J93L');
          `}
        </script>
      </Helmet>
    </>
  );
};

export default GoogleTagManager;
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import ModalMessage from './ModalMessage'; 
import Loading      from './Loading';
import ConfirmModal from './ConfirmModal-password';

import * as utils from './Utils';
import { MD5 } from 'crypto-js';

import './App.css';

const Logo = () => {

  return (
    <div className="flex justify-center items-center mb-2">
      <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
    </div>
  );
};

function App() {

  const [loading, setLoading] = useState(true);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const location = useLocation();

  const [formData, setFormData] = useState({
    cpf:  '',
    pass: '',
    code: ''
  });  

  const cartUUID  = localStorage.getItem('cartUUID' );
  const eventUUID = localStorage.getItem('eventUUID');

  useEffect(() => {

    setLoading(false);

    setConfirmModalOpen(true);

  }, []);

  const handleConfirmModalClose = () => {
    
    setConfirmModalOpen(false);
  };

  function convertPassword(pw) {
  
    return MD5(pw + "MDL#world").toString();
  }

  const handleConfirmRegistration = async (pass) => {

    setLoading(true);

    const params = new URLSearchParams(location.search);

    formData.cpf  = params.get('cpf');
    formData.code = params.get('code');    
    formData.pass = convertPassword(pass);
 
    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/change/pass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (!result) {

        throw new Error("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.\Status: " + response.status);
      }
      else if (response.status == 409) {

        setModalMessage(result.msg);
        setModalCalback(() => {
          return () => {

            setLoading(false);

            localStorage.setItem('mdlCPF', formData.cpf);

            window.location.href = '/login';
          };
        });
      }
      else if (!response.ok) {

        throw new Error(result.msg);
      }
      else { //success:

        { //store user info:

          localStorage.setItem('mdlToken', result.token);
          localStorage.setItem('mdlEmail', result.email);
          localStorage.setItem('mdlName',  result.name);
          localStorage.setItem('mdlAdmin', result.admin);
  
          localStorage.setItem('mdlCartPaid', '');
          for(const cp of result.cartsPaid) {
  
            if (cp.eventUUID == eventUUID) {
  
              localStorage.setItem('mdlCartPaid', cp.cartUUID);
              break;
            }
          }
  
          localStorage.setItem('mdlCartRegistered', '');
          for(const cr of result.cartsRegistered) {
  
            if (cr.eventUUID == eventUUID) {
  
              localStorage.setItem('mdlCartRegistered', cr.cartUUID);
              break;
            }
          }
        }
  
        const loginRedirect = localStorage.getItem('loginRedirect');
        if (loginRedirect) {

          window.location.href = loginRedirect;
        }
        else  {

          const landing = params.get('landing');
          if (landing)
            window.location.href = landing;
          else
            window.location.href = '/purchase';
        }
      }
    }
    catch(error) {

      console.error('Error logging in: ', error.message);

      setModalMessage(error.message);
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }
  }; 

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  return (
    <div style={bodyStyle}>
      <div className="container bg-white">
        {loading && (<Loading />)}
        {!loading && (
          <div>
          </div>
        )}

        <ConfirmModal
          user={formData.cpf}
          isOpen={isConfirmModalOpen}
          onConfirm={handleConfirmRegistration}
        /> 

        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       

      </div>
    </div>
  );
}

export default App;

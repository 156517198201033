// ModalMessage.js
import React, { useEffect } from 'react';

const ModalMessage = ({ message, onClose }) => {

  return (
    <div className="fixed inset-0 flex items-center justify-center m-2">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 rounded-lg z-10">
        <h2 className="text-lg font-bold mb-4 text-center" style={{ maxWidth: '450px', whiteSpace: 'pre-line' }}>{message}</h2>
        <div className="flex justify-center">
          <button autoFocus id="modalOk" className="bg-orange-400 text-white px-4 py-2 text-xl font-bold rounded" onClick={onClose} style={{ minWidth: '150px' }}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalMessage;
import React, { useState, useEffect, useRef } from 'react';

import InputMask from 'react-input-mask';

import ModalMessage from './ModalMessage';
import './App.css';

import * as utils from './Utils';

class EventBanner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      eventInfo: {}
    };
  }

  componentDidMount() {

    fetch(process.env.REACT_APP_API_ENDPOINT + '/events/' + this.props.uuid, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }}
      ).then(response => {

        if (!response.ok)
          throw new Error('Failed to fetch event info');

        return response.json();
      })
      .then(eventInfo => {

        this.setState({ eventInfo });

        fetch(process.env.REACT_APP_API_ENDPOINT + '/events/' + eventInfo.eventUUID + '/banner', {
          method: 'GET',
          headers: {
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }}
          ).then(response => {
    
            if (!response.ok)
              throw new Error('Failed to fetch event banner');
    
            return response.blob();
          })
          .then(imageBlob => {
    
            const imageUrl = URL.createObjectURL(imageBlob);
            this.setState({ imageUrl });
          })
          .catch(error => {
    
            console.error('Error fetching event banner:', error);
          }
        );
      })
      .catch(error => {

        console.error('Error fetching event info:', error);
      }
    );
  }

  componentWillUnmount() {

    URL.revokeObjectURL(this.state.imageUrl);
  }  

  render() {
    const { imageUrl } = this.state;
    const { eventInfo } = this.state;
    return (
      <div className="flex justify-center items-center border-2">
        { imageUrl && <img src={imageUrl} alt="Event Banner" className="max-w-full h-auto" /> }
        { /* eventInfo && <h1 className="text-center text-1xl font-bold mt-1 text-black">{eventInfo.name}</h1> */ }
      </div>
    );
  }
}

export default EventBanner;
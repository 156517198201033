import React, { useState, useEffect, useRef } from 'react';

import InputMask from 'react-input-mask';

import ConfirmModal from './ConfirmModal-registration-email';
import ModalMessage from './ModalMessage';
import Loading      from './Loading';
import EventHeader  from './EventHeader'
import './App.css';
import * as utils from './Utils';

import { css } from '@emotion/react';
import { BarLoader } from 'react-spinners';

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" &&  window.performance.navigation.type === 2 );
  if ( historyTraversal ) { window.location.reload(); } });

const fetchCart = async (uuid) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/cart/' + uuid, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching items: ', error);
    throw error;
  }
};

function App() {

  const [loading,            setLoading         ] = useState(true);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalMessage,       setModalMessage    ] = useState(null);
  const [modalNextFocus,     setModalNextFocus  ] = useState(null);
  const [modalCallback,      setModalCalback    ] = useState(null);
  const [emailSent,          setEmailSent       ] = useState(false);

  const [formData, setFormData] = useState({
    token:    '',
    email:    '',
    cartUUID: ''
  });    

  const eventUUID = localStorage.getItem('eventUUID');
  const cartUUID  = localStorage.getItem('cartUUID' );
  const cartCode  = localStorage.getItem('cartCode' );
  const mdlToken  = localStorage.getItem('mdlToken' );
  const mdlEmail  = localStorage.getItem('mdlEmail' );

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  useEffect(() => {

    setEmailSent(false);
    setLoading(false);
  
  }, []);

  const handleConfirmModalCancel = () => {

    setConfirmModalOpen(false);
  };

  const handleConfirmChangeEmail = async (email) => {

    setLoading(true);

    formData.token = mdlToken;
    formData.email = email;
 
    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/change/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (!result) {

        throw new Error("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.\Status: " + response.status);
      }
      else if (!response.ok) {

        throw new Error(result.msg);
      }
      else { //success:

        //mdlEmail = result.email;
        //mdlToken = result.token;

        localStorage.setItem('mdlToken', result.token);
        localStorage.setItem('mdlEmail', result.email);

        setLoading(false);

        handleSendEmail();
      }
    }
    catch(error) {

      console.error('Error logging in: ', error.message);

      setModalMessage(error.message);
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }

    if (modalNextFocus) {

      modalNextFocus.focus();
      setModalNextFocus(null);
    }
  };

  const handleChangeEmail = async () => {

    setConfirmModalOpen(true);
  }
  
  const handleSendEmail = async () => {

    setLoading(true);

    formData.token = mdlToken;
    formData.email = mdlEmail;    

    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'cart/' + cartUUID + '/receipt/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok)
        throw new Error(response.message);

      const result = await response.json();
      if (!result || result.status != 'OK')
        throw new Error("Result: " + result.msg);

      setLoading(false);
      setEmailSent(true);      
    }
    catch (error) {

      console.error('Error updating sending email: ', error);

      setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }
  }

  const handlePrintReceipt = async () => {

    setLoading(true);

    formData.token = mdlToken;
    formData.email = mdlEmail;
    
    const printWindow = window.open('', '_blank');

    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'cart/' + cartUUID + '/receipt/html', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok)
        throw new Error(response.message);

      const result = await response.json();
      if (!result || result.status != 'OK')
        throw new Error("Result: " + result.msg);

      printWindow.document.write(result.receiptHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      setTimeout(() => { printWindow.close();  }, 250);
      //printWindow.onafterprint = () => { printWindow.close(); };

      //printWindow.document.write(result.receiptHTML);
      //printWindow.document.close();
      //printWindow.focus();
      //printWindow.print();
      //setTimeout(() => { printWindow.close();  }, 250);

      setLoading(false);
    }
    catch (error) {

      console.error('Error printing receipt: ', error);
      printWindow.close(); 

      setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }
  }

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
      {loading && (<Loading />)}
        {!loading && (
          <div>
            <div className="flex justify-center items-center mb-4">
              <EventHeader uuid={eventUUID} />
            </div>

            <div className="mt-40">
              <h1 className="text-center text-2xl font-bold mb-4">Inscrição finalizada!</h1>
            </div>

            <div className="flex justify-center items-center mt-8 m-2">
              <button
                className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold animate-glowing-green"
                onClick={handlePrintReceipt}
                style={{ minWidth: '324px' }}>
                Gerar comprovante
              </button>
            </div>

            {cartCode&&(
              <div className="mt-8">
                <h1 className="text-center text-1xl">Anote este código:</h1>
                <h1 className="text-center text-1xl font-bold mb-4">{cartCode}</h1>
              </div>
            )}

            <div>

              {!emailSent&&(<div className="mt-10 m-1">
                <div className="text-center text-black font-boldtext-1xl m-1">Gostaria de receber o resumo desta inscrição por email?</div>
              </div>)}

              {emailSent&&(<div className="mt-10 m-1">
                <div className="text-center text-black font-boldtext-1xl m-1">Resumo da inscrição foi enviado para:</div>
              </div>)}

              <div className="mt-5 m-1">
                <div className="text-center text-gray-500 text-1xl font-bold border-2 bg-white p-2">{mdlEmail}</div>
              </div>

              {!emailSent&&(<div className="flex justify-center items-center mt-8 m-2">

                <button
                  className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold mr-2"
                  onClick={handleChangeEmail}
                  style={{ minWidth: '150px' }}>
                  Alterar email
                </button>

                <button 
                  type="submit"
                  className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ml-2`}
                  onClick={handleSendEmail}
                  style={{ minWidth: '150px' }}>
                  Enviar
                </button>

              </div>)}

            </div>

            <ConfirmModal
              isOpen={isConfirmModalOpen}
              onClose={handleConfirmModalCancel}
              onConfirm={handleConfirmChangeEmail}
            /> 
            {modalMessage && (
              <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
            )}                       

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       
      </div>
    </div>
  );
}

export default App;
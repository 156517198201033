// Loading.js
import React from 'react';
import { css } from '@emotion/react';
import { BarLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loading = () => {
  return (
    <div className="loading-container">
      <BarLoader color="#D87336" css={override} loading={true} size={250} />
    </div>
  );
};

export default Loading;
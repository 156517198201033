import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ModalMessage from './ModalMessage'; 
import Loading      from './Loading';
import EventHeader  from './EventHeader'
import EventBanner  from './EventBanner'
import './App.css';

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" &&  window.performance.navigation.type === 2 );
  if ( historyTraversal ) { window.location.reload(); } });

const fetchEvent = async (uuid) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'events/' + uuid, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching event: ', error);
    throw error;
  }
};

function App() {

  const [loading,       setLoading     ] = useState(true);
  const [event,         setEvent       ] = useState({});
  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const { eventName } = useParams();

  const eventUUID = (eventName && eventName.length > 0 ? eventName : 
    (window.location.hash.substring(1) && window.location.hash.substring(1).length > 0 ? window.location.hash.substring(1) : ''));

  useEffect(() => {

    if (!eventUUID || eventUUID.length == 0) { //event not defined:

      console.log("Error: event not defined.");
      window.location.href = process.env.REACT_APP_LANDING_DEFAULT;
      return;
    }

    localStorage.setItem('eventUUID', eventUUID);
    localStorage.setItem('landingTag', eventName && eventName.length > 0 ? eventName : window.location.hash.substring(1).length);

    const loadEvent = async () => {

      try {

        //console.log('loaded');

        const event = await fetchEvent(eventUUID);

        if (event.status == 'OK') {

          if (event != null) {

            localStorage.setItem('eventUUID', event.eventUUID);

            setEvent(event);
            setLoading(false);
          }
          else {
  
            throw new Error('Could not retrieve event information');
          }
        }
        else {

          setModalMessage(event.msg);
          setModalCalback(() => {
            return () => {
              window.location.reload();
            };
          });
        }
      }
      catch(error) { //event not defined:

        console.log("Error: " + error.message);
        window.location.href = process.env.REACT_APP_LANDING_DEFAULT;
      }
    };

    localStorage.setItem('loginRedirect', '/purchase');

    loadEvent();

  }, []);


  const handleStartButtonClick = () => {

    localStorage.setItem('loginRedirect', '/purchase');
    window.location.href = '/login';
  }

  const handleModalMessageClose = () => {

    setModalMessage(null); // Clear the modal message

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }
  };  

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <div className="flex justify-center items-center mb-4">
              <EventHeader uuid={eventUUID} />
            </div>
            <div className="mt-24">

              <div className="flex justify-center items-center mb-4"
                onClick={handleStartButtonClick}
              >
                <EventBanner uuid={eventUUID}/>
              </div>

              { /* event.details && (<div className="text-center text-1xl font-bold mb-2 whitespace-pre-line">
                <h1 className="text-center text-1xl font-bold mb-2 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: event.details }} />
                </div>) */ }

              <div className="mt-8">
                <div className="text-center text-1xl m-4 font-bold">INSCRIÇÕES ONLINE:</div>
                <div className="text-center text-1xl m-2">1. Tenha em mãos o nome e o CPF de todos os laçadores</div>
                <div className="text-center text-1xl m-2">2. Cadastre-se ou faça seu login MDL</div>
                <div className="text-center text-1xl m-2">3. Selecione as categorias</div>
                <div className="text-center text-1xl m-2">4. Faça o Pix</div>
                <div className="text-center text-1xl m-2">5. Escolha os números (se disponível)</div>
                <div className="text-center text-1xl m-2">6. Informe CPF e nome dos participantes</div>
              </div>
            </div>

            <div className="mb-20 mt-8">

              { event.supportWhatsapp && (
                <div className="text-center text-1xl font-bold mb-8 whitespace-pre-line">
                  <a style={{color:"green"}} href={"https://wa.me/" + event.supportWhatsapp + "?text=" + "Olá! Tenho uma dúvida sobre o evento " + event.name}>
                    <img align="center" style={{height:"50px",padding:"0px 10px",display:"inline"}} src="assets/whatsapp-icon-green.png" />Dúvidas? Fale por Whatsapp aqui!
                  </a>
                </div>
              ) }

              { event.support && (<div className="text-center text-1xl font-bold mb-8 whitespace-pre-line">
                <h1 className="text-center text-1xl font-bold mb-2 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: event.support }} />
                </div>) }

              { /*event.details && (<div className="text-center text-1xl mb-8 whitespace-pre-line">
                <h1 className="text-center text-1xl mb-2 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: event.details }} />
                </div>) */ }

            </div>

            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300">
              <div className="container bg-white mx-auto p-4 max-w-screen-md">
                <div className="flex justify-end items-center">
                  <button className="bg-orange-400 text-white text-xl font-bold px-4 py-2 rounded"
                    style={{ minWidth: '150px' }}
                    onClick={handleStartButtonClick}
                    >
                    Iniciar
                  </button>
                </div>
              </div>
            </div>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       

      </div>
    </div>
  );
}

export default App;

// EulaDialog.js

import React, { useState } from 'react';

const EulaDialog = ({ eulaTitle, eulaText, onAgree, onCancel }) => {

  const [isChecked, setIsChecked] = useState(false);

  const handleAgree = () => {
    if (isChecked) {
      onAgree();
    }
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg overflow-auto max-h-screen">
        {/*<button 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={handleClose}>
          ✖
        </button>*/}

        <h2 className="text-xl font-bold mb-4">{eulaTitle}</h2>
        <div className="text-gray-700 space-y-4">
          <div dangerouslySetInnerHTML={{ __html: eulaText }} />
        </div>

        <div className="mt-4 flex justify-center items-center text-l font-bold mb-4">
          <input
            id="agreeCheckbox"
            type="checkbox"
            className="mr-2 h-8 w-6"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <label htmlFor="agreeCheckbox">Li e concordo com os termos acima</label>
        </div>
        <div className="mt-4 flex justify-center items-center text-l font-bold mb-4">
          <button
            className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ${
              isChecked ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
            }`}
            style={{ minWidth: '150px' }}
            onClick={handleAgree}
            disabled={!isChecked}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );  
};

export default EulaDialog;
